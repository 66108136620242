

.tooltip-pricing-label{
    position: absolute;
    cursor: pointer;
    color: #1b7afa;
    margin-top: -7px;
    font-size: 19px !important;
    margin-left: 4px; 
    font-family: 'Montserrat', sans-serif;
}

.tooltip-pricing-label div{
    font-family: 'Montserrat', sans-serif;
}

.pricing-table-dot{

    color: #0066f3;
    font-size: 12px !important;
    margin: 5px 5px;
    position: relative;
    top: 5px;
}
.simulatorPrice-webmarketing-intro{
    position: relative;
    margin-top: -55px;
}