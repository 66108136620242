
/** google button **/
.google-button {
    display: inline-block;
    background-color: #4285F4;
    color: #fff;
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25), 0 3px 10px 0 rgba(0,0,0,0.12);
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    cursor: pointer;
  }
  
  .google-icon-wrapper {
    display: inline-block;
    height: 36px;
    width: 36px;
    border-radius: 2px;
    background-color: #fff;
    position: relative;
    margin-right: 16px;
    top: -10px;
  }
  
  .google-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 18px;
    width: 18px;
  }
  .google-button span{ 
    position: relative;
    top: -21px; 
  }
/** facebook button **/
.facebook-button {
  display: inline-block;
  background-color: #3B5998;
  color: #fff;
  border-radius: 2px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25), 0 3px 10px 0 rgba(0,0,0,0.12);
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  cursor: pointer;
  position: relative;
  margin-left: 20px;
  top: -10px;
  margin-top: 15px;
}
.facebook-button i {
  font-size: 20px;
  margin-right: 8px;
}
.button-text {
  margin-left: 8px;
}
/** instagram **/
.instagram-button {
  display: inline-block;
  background-color: #C13584;
  color: #fff;
  border-radius: 2px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25), 0 3px 10px 0 rgba(0,0,0,0.12);
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  cursor: pointer;
  position: relative;
  margin-left: 20px;
  top: -10px;
  margin-top: 5px;
}
.instagram-button i {
  font-size: 20px;
  margin-right: 8px;
}
.button-text {
  margin-left: 8px;
}
/** twitter **/
.twitter-button {
  display: inline-block;
  background-color: #1DA1F2;
  color: #fff;
  border-radius: 2px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25), 0 3px 10px 0 rgba(0,0,0,0.12);
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  cursor: pointer;
  position: relative;
  margin-left: 20px;
  top: -10px;
  margin-top: 5px;
}
.twitter-button i {
  font-size: 20px;
  margin-right: 8px;
}
.button-text {
  margin-left: 8px;
}













/* Example CSS */
.google-button {
  background-color: #ffffff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 27px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: black;
}

.google-button:hover {
  background-color: #fafafa;
}
